import type { StoryblokStory } from 'storyblok-generate-ts';
import Bugsnag from '@bugsnag/js';

export function getResolvedField<T>(field: StoryblokStory<T> | string): StoryblokStory<T> | string {
  if (typeof field === 'string') {
    Bugsnag.notify('Expected field to be resolved, instead found UUID.');
    return '';
  }
  return field;
}

export const getResolvedArrayField = <T>(field: (StoryblokStory<T> | string)[]): (StoryblokStory<T> | string)[] =>
  field.map(getResolvedField).filter(item => item !== '');
